<template lang="pug">
span(:class="{'z-checkbox':true, 'z-checkbox-checked':!!props.modelValue}" @click="checkboxClick")
    mdicon(:name="currentIcon" class="z-checkbox__box")
    span(v-html="label" class="z-checkbox__label")
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
    modelValue: { type: Boolean, default: false },
    label: { type: String, default: '' }
})

const emit = defineEmits(['update:modelValue'])

const currentIcon = computed(()=> (!props.modelValue) ? "checkbox-blank-outline" : "checkbox-marked")

function checkboxClick () {
    emit('update:modelValue', !props.modelValue)
}
</script>

<style>
    .z-checkbox{
        /*width: 100%;*/
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    .z-checkbox__label{
        font-size: var(--font-size);
        margin: 0 var(--indent-size);
    }

    .z-checkbox__box{
        min-width: 1.5rem;
    }

    .z-checkbox-checked>.z-checkbox__box{
        color: var(--primary-color)
    }
</style>
