<template lang="pug">
div(class="z-confirm")
    div(class="z-card z-confirm__card")
        div(class="z-confirm__card__message") {{ message }}
        div(class="z-confirm__card__buttons")
            z-btn(@click="yesClick") Да
            z-btn(@click="noClick") Нет
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
    message: {type: String, required: true}
})

const emit = defineEmits(['yes', 'no'])

function yesClick () {
    emit('yes')
}

function noClick () {
    emit('no')
}
</script>

<style scoped>
    .z-confirm {
        /*z-index: 9;*/
        /*position: fixed;*/
        /*top: 3rem;*/
        /*left: 50%;*/
        /*transform: translateX(-50%);*/
        z-index: 9;
        position: absolute;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .z-confirm__card{
        padding: var(--indent-size-big);
    }

    .z-confirm__card__message{
        margin-bottom: var(--block-height-medium);
        font-size: var(--font-size-big);
        color: var(--regular-text-color);
    }

    .z-confirm__card__buttons{
        display: flex;
        justify-content: space-between;
    }

    .z-confirm__card__buttons button{
        margin-right: var(--indent-size);
        min-width: 4rem;
    }

    .z-confirm__card__buttons button:nth-last-child(1){
        margin-right: 0;
    }

</style>
